import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Heading = (props) => {
    return (
        <div className='details'>
            <div className='detail-imgs'>
                {/* <StaticImage src='../../static/images/full-screen.png' class='header-img'/> */}
                <StaticImage
                    src='../../static/images/header-4.png'
                    className='header-img'
                    alt=''
                />
                <StaticImage
                    src='../../static/images/header-3.png'
                    className='header-img'
                    alt=''
                />
                <StaticImage
                    src='../../static/images/header-2.png'
                    className='header-img'
                    alt=''
                />
                <StaticImage
                    src='../../static/images/header-1.png'
                    className='header-img'
                    alt=''
                />
            </div>

            {/* include breadcrumbs here */}
            <h1 className=''>{props.title ? props.title : ''}</h1>
            <p className=''>
                <time dateTime=''></time>
            </p>
        </div>
    );
};

export default Heading;
