import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

const ChapterLinks = ({ mdx }) => {
    const H2 = ({ id, ...rest }) => (
        <li>
            <a href={`#${id}`} {...rest} />
        </li>
    );
    const H3 = ({ id, ...rest }) => (
        <li>
            <ul className='uk-list uk-list-hyphen uk-list-muted uk-margin-remove'>
                <li className='uk-margin-remove'>
                    <a href={`#${id}`} {...rest} />
                </li>
            </ul>
        </li>
    );
    const empty = (props) => <></>;
    const linkComponents = {
        p: empty,
        h1: empty,
        h2: H2,
        h3: H3,
        h4: empty,
        h5: empty,
        h6: empty,
        thematicBreak: empty,
        blockquote: empty,
        ul: empty,
        ol: empty,
        li: empty,
        table: empty,
        tr: empty,
        pre: empty,
        code: empty,
        em: empty,
        strong: empty,
        delete: empty,
        inlineCode: empty,
        hr: empty,
        a: empty,
        img: empty,
        dl: empty,
        dt: empty,
        Accordian: empty,
        Alert: empty,
        Tooltip: empty,
        div: empty,
    };

    return (
        <ul className='uk-list'>
            <MDXProvider components={linkComponents}>
                <MDXRenderer>{mdx}</MDXRenderer>
            </MDXProvider>
        </ul>
    );
};

export default ChapterLinks;
