import React from 'react';
import Heading from '../components/Heading';
import ChapterLinks from '../components/ChapterLinks';
import Sidebar from '../components/Layout/Sidebar';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Accordian from '../components/Markdown/Accordian';
import Alert from '../components/Markdown/Alert';
import Tooltip from '../components/Markdown/Tooltip';

const Post = ({ data }) => {
    const post = data.strapiPost;
    const relatedPosts = data.relatedStrapiPosts

    const ukStyles = {
        ul: (props) => (<ul {...props} class="uk-list" />),
        dl: (props) => (<dl {...props} class="uk-description-list" />),
        table: (props) => (<table {...props} class="uk-table" />),
        Accordian,
        Alert,
        Tooltip,
    }
    return (
        <>
            <Heading title={post.title} />
            <div className='e-sidebar-left uk-width-1-5 uk-visible@m uk-padding uk-padding-remove-vertical uk-padding-remove-left'>
                <ChapterLinks mdx={post.children[0].childMdx.body} />
            </div>

            <section className='uk-width-expand uk-padding uk-padding-remove-vertical'>
                <MDXProvider components={ukStyles}>
                    <MDXRenderer>{post.children[0].childMdx.body}</MDXRenderer>
                </MDXProvider>
            </section>

            <Sidebar postData={post} relatedPostData={relatedPosts} />
            
        </>
    );
};

export default Post;

export const pageQuery = graphql`
    query BlogPostBySlug($pageSlug: String, $pageID: String) {
        strapiPost(slug: { eq: $pageSlug }) {
            id
            slug
            body
            title
            categories {
                name
            }
            children {
                ... on ContentMDX {
                    childMdx {
                        body
                    }
                }
            }
        }

        relatedStrapiPosts (parent: {id: {eq: $pageID}}) {
            posts {
                title
                slug
                categories {
                    slug
                }
            }
        }
    }
`;
