import React from 'react'

const Accordian = (props) => {
  return (
      <ul className='uk-accordion'>
        <li>
            <a class="uk-accordion-title" href="#">{props.title}</a>
            <div class="uk-accordion-content" {...props} />
        </li>
    </ul>
  )
}

export default Accordian