import React from 'react'
import { Link } from 'gatsby'

const Sidebar = ({postData, relatedPostData, pageData, relatedPageData}) => {

    const relatedList = []
    if (relatedPostData) {
        relatedPostData.posts.map((post) => {
            return relatedList.unshift( <li><Link to={'../../' + post.categories[0].slug + '/' + post.slug}>{post.title}</Link></li> )
        })
    }
    if (relatedPageData) {
        relatedPageData.posts.map((page) => {
            return relatedList.unshift( <li><Link to={'../../' + page.slug}>{page.title}</Link></li> )
        })
    }

  return (
    <div className='uk-width-1-4'>
        <div className='uk-card uk-card-default uk-card-body uk-margin uk-padding-small'>
            <h5>search</h5>
        </div>
        <div className='uk-card uk-card-default uk-card-body uk-margin uk-padding-small'>
            <h5>related/trending</h5>
            <ul>
                {relatedList.map(i => {return i})}
            </ul>
        </div>
        <div className='uk-card uk-card-default uk-card-body uk-margin uk-padding-small'>
            <h5>archives/categories</h5>
        </div>
        <div className='uk-card uk-card-default uk-card-body uk-margin uk-padding-small'>
            <h5>donate</h5>
        </div>
        <div className='uk-card uk-card-default uk-card-body uk-margin uk-padding-small'>
            <p>Some links on this website are affiliate with various platforms. I recieve a small commission from sales via these links. Commissions are always paid by the retailer at no cost to you.</p>
        </div>
    </div>
  )
}

export default Sidebar